// composables/useGlobalStore.ts

import { ref, computed } from 'vue';
import { useState, useAsyncData } from '#app';

interface Global {
    title?: string;
    description?: string;
    siteurl?: string;
    logo: string;
    logoInner: string;
    aboutTitle: string;
    aboutSubTitle: string;
    aboutDescription: string;
    serviceTitle: string;
    serviceSubtitle: string;
    clientTitle: string;
    clientSubTitle: string;
   blogTitle: string;
    blogSubTitle: string;

}

interface Heading {
    headingImage: string;
    headingTitle: string;
    headingSubTitle: string;
}

export function useGlobalStore() {
    // State
    const globalItem = useState<Global | null>('globalItem', () => null);
    const headingItem = useState<Heading | null>('headingItem', () => null);

    // Actions
    const setGlobalItem = (item: Global | null) => {
        globalItem.value = item;
    };

    const fetchGlobalData = async () => {
        if (!globalItem.value) {
            try {
                const { getSingletonItem } = useDirectusItems();
                const data = await getSingletonItem<Global>({ collection: "global" });
                setGlobalItem(data);
            } catch (e) {
                console.error("Failed to fetch data:", e);
                setGlobalItem(null); // Set to null to indicate failure
            }
        }
    };

    const setHeadingItem = (item: Heading | null) => {
        headingItem.value = item;
    };

    const fetchHeadingData = async () => {
        if (!headingItem.value) {
            try {
                const { getSingletonItem } = useDirectusItems();
                const data = await getSingletonItem<Heading>({ collection: "heading" });
                setHeadingItem(data);
            } catch (e) {
                console.error("Failed to fetch data:", e);
                setHeadingItem(null); // Set to null to indicate failure
            }
        }
    };

    // Getters
    const title = computed(() => globalItem.value?.title || 'loading');
    const description = computed(() => globalItem.value?.description || 'loading');
    const siteurl = computed(() => globalItem.value?.siteurl || 'loading');
    const logo = computed(() => globalItem.value?.logo || 'loading');
    const logoInner = computed(() => globalItem.value?.logoInner || 'loading');
    const aboutTitle = computed(() => globalItem.value?.aboutTitle || 'loading');
    const aboutSubTitle = computed(() => globalItem.value?.aboutSubTitle || 'loading');
    const aboutDescription = computed(() => globalItem.value?.aboutDescription || 'loading');
    const serviceTitle = computed(() => globalItem.value?.serviceTitle || 'loading');
    const serviceSubtitle = computed(() => globalItem.value?.serviceSubtitle || 'loading');
    const clientTitle = computed(() => globalItem.value?.clientTitle || 'loading');
    const clientSubTitle = computed(() => globalItem.value?.clientSubTitle || 'loading');
    const blogTitle = computed(() => globalItem.value?.blogTitle || 'loading');
    const blogSubTitle = computed(() => globalItem.value?.blogSubTitle || 'loading');
    
    const headingImage = computed(() => headingItem.value?.headingImage || 'loading');
    const headingTitle = computed(() => headingItem.value?.headingTitle || 'loading');
    const headingSubTitle = computed(() => headingItem.value?.headingSubTitle || 'loading');

    return {
        globalItem,
        fetchGlobalData,
        title,
        description,
        siteurl,
        logo,
        logoInner,
        aboutTitle,
        aboutSubTitle,
        aboutDescription,
        serviceTitle,
        serviceSubtitle,
        clientTitle,
        clientSubTitle,
        blogTitle,
        blogSubTitle,
        headingItem,
        fetchHeadingData,
        headingImage,
        headingTitle,
        headingSubTitle,
    };
}
